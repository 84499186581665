import React from "react"
import { graphql } from "gatsby"
import LayoutNoStripe from "../components/layoutNoStripe"
import SEO from "../components/seo"
import {
  StyledAboutGift,
  AboutGiftContent
} from "../styledComponents/gift"
import { BackgroundColourBlock } from "../styledComponents/overView"
import HeroBanner from "../components/HeroBanner"
import { HeroText, HeroHeader, HeroDescription } from "../styledComponents/heroBanner"
import Icon from "../components/icon"
import {StyledBlockQuote} from "../styledComponents/base"
import {
  Section,
  SectionHeading
} from "../styledComponents/section"
import GiftList from "../components/GiftList"
import giftData from "../fixtures/gifts"
import { StyledAnchorLink } from "../styledComponents/button";

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Gift = ({ data, location }) => {
    return (
      <LayoutNoStripe location={location} isHomePage>
        <SEO 
          title="Buy a gift" 
          description="A forever gift for a loved one - Give a life-changing gift and make an impact that lasts forever." 
          url="/gift"
          />
        <HeroBanner image="/images/karina-grant-gift.jpg" type="gift">
            <HeroText>
              <HeroHeader isGift='true'>
              <strong>A forever gift for a loved one</strong>
              </HeroHeader>
              <HeroDescription isGift='true'>Give a life-changing gift and make an impact that lasts forever.</HeroDescription>
              <StyledAnchorLink inverse="true" className="learn-more" to="/gift#buy">Buy a gift</StyledAnchorLink>
            </HeroText>
        </HeroBanner>

        <StyledAboutGift itemScope itemType="http://schema.org/Article" id="info">
          <h2>The greatest joy in the world comes from helping another human being to experience higher levels of happiness.</h2>
          <AboutGiftContent itemProp="articleBody">
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>Give what you want to receive. If you want happiness, make others happy.</p>
              <img src="/images/russell-simmons.jpg" alt="Russell Simmons "/>
              <cite>Russell Simmons</cite>
            </StyledBlockQuote> 
            {/* <p>The best part is that<strong> when we give we receive</strong>. This is a Universal Law. As we make ourselves into a vessel that shares light with others, we are blessed with much more light to share.</p> */}
          </AboutGiftContent>
        </StyledAboutGift>

        <BackgroundColourBlock number='2' id="buy">
          <Section>
            <Heading level={1} align="center">
              Gift shop
            </Heading>
            <GiftList posts={giftData[0].gifts} />
          </Section>
        </BackgroundColourBlock>
      </LayoutNoStripe>
    )
}

export default Gift


export const pageQuery = graphql`
  query {
    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

